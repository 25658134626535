<template>
  <div>
    <div class="breadcrumb_header">
      <div class="breadcrumb ms-2">
        <h4 style="margin: 0; padding-left: 10px">NeXa</h4>
        <span class="divider"></span>
        <span class="breadcrumb-text">Use Cases / Draft a Legal Memo</span>
      </div>
      <div>
        <button @click="toggleSidebar" class="open_sidebar me-2">
          <span class="material-symbols-rounded icon"
            >keyboard_double_arrow_left</span
          >
          <p>Open Sidebar</p>
        </button>
      </div>
    </div>

    <div class="row">
      <div class="col-md-1 mt-3 p-0">
        <div
          class="d-flex justify-content-end cursor-pointer back"
          @click="goBack"
        >
          <span class="material-symbols-rounded" style="margin: 0"
            >arrow_back</span
          >
          <span style="margin: 0 0 0 8px">Back</span>
        </div>
      </div>
      <div class="col-md-10 mt-5 p-0">
        <loader v-if="loading"></loader>
        <div v-else>
          <section
            class="claims-container"
            v-for="q in filteredQuestions"
            :key="`questions-${q.id}`"
            :id="`q-${q.id}`"
          >
            <FollowUpQuestionBox :q="q" />
          </section>

          <main class="content mt-3">
            <section class="intro">
              <h6 class="title">Draft a Legal Memo</h6>
              <hr class="separator" />
            </section>

            <section class="question-section">
              <div class="tabs">
                <!-- <div 
                v-for="(q, index) in questions"
                :key="`questions-${q.id}`"
                :id="`q-${q.id}`"
                class="tab d-flex gap-2 align-items-center"
                > -->
                <div class="tab d-flex gap-2 align-items-center">
                  <h6 class="question m-0">Statement:</h6>
                  <button class="button pe-none">
                    <img
                      :src="countryImages[jurisdiction]"
                      alt="Country flag"
                      class="img me-2"
                    />
                    <span>{{ jurisdiction }}</span>
                  </button>
                </div>
              </div>
              <p class="question-description">
                {{ questions[0]?.question || "(NO DATA)" }}
              </p>

              <div>
                <div class="tabs">
                  <div class="tab d-flex gap-2 align-items-center">
                    <h6 class="question m-0">Uploaded Document Summary</h6>
                    <button class="button" @click="toggleSidebar">
                      <span class="text-link"
                        >{{ files.length }} Files Uploaded</span
                      >
                    </button>
                  </div>
                </div>
                <p class="question-description">-</p>
              </div>

              <button class="button" @click="viewSummary">
                <span class="text-link"> View Full Summary</span>
              </button>
            </section>

            <hr class="separator" />
            <section class="answer-section">
              <div class="answer-header mt-2">
                <div class="tab d-flex gap-2 align-items-center">
                  <h6 class="answer-title">Generated Argument</h6>
                </div>
                <div class="actions">
                  <button
                    class="button me-2"
                    style="border-width: 0px; color: #0e4485 !important"
                    @click="copy"
                  >
                    <img
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/4042ce02169404fc8f24e2435141f1518abb04892a209162cf916b7ff7de4ac3?placeholderIfAbsent=true&apiKey=b7b3d02748134357b1767f32084c4983"
                      alt="Copy icon"
                      class="img"
                    />
                    <span>{{ isCopied ? "Copied" : "Copy" }}</span>
                  </button>
                  <b-dropdown
                    size="sm"
                    id="dropdown-right"
                    right
                    offset="-9"
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                    @click="toggleDropdown"
                  >
                    <template #button-content>
                      <button class="action-btn export-btn">
                        <span class="button-label">Export</span>
                        <img
                          :class="{ 'rotate-180': isDropdown }"
                          src="https://cdn.builder.io/api/v1/image/assets/TEMP/d12668b437286b44ce443817869f8a85013f194276d09cf30b58481266006963?placeholderIfAbsent=true&apiKey=b7b3d02748134357b1767f32084c4983"
                          alt="Export Icon"
                          class="icon"
                        />
                      </button>
                    </template>

                    <b-dropdown-item class="ms-1 me-1" @click="exportToPDF">
                      <img class="me-2" src="@/assets/icon/pdf-icon.svg" />
                      <span class="dropdown-text">Export as PDF</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
              <p class="answer-text">
                Based on your query and uploaded documents, NeXa has generated
                the following insights.
              </p>
              <div class="tabs" id="introduction">
                <div class="tab d-flex gap-2 align-items-center">
                  <h6 class="question mt-2">Introduction</h6>
                </div>
              </div>
              <p class="answer-text m-0">
                {{ introduction }}
              </p>
            </section>

            <section class="discussion mt-4">
              <div class="tabs">
                <div class="tab d-flex gap-2 align-items-center">
                  <h6 class="question m-0">Discussion:</h6>
                </div>
              </div>
              <div style="background-color: #fafbfc; border-radius: 16px">
                <quill-editor
                  class="mt-2 quillEditor"
                  v-model="discussion"
                  :options="optionsContent"
                >
                </quill-editor>
                <div class="d-flex justify-content-end me-4 pb-3">
                  <button class="button me-2" style="background-color: #fff">
                    <span style="color: #000046; font-size: 14px">Cancel</span>
                  </button>
                  <button
                    class="button button-primary"
                    style="background-color: #0e4485"
                  >
                    <span style="color: #fff; font-size: 14px">Save</span>
                  </button>
                </div>
              </div>
              <div class="tabs mt-4">
                <div class="tab d-flex gap-2 align-items-center">
                  <h6 class="question mt-2">Conclusion</h6>
                </div>
                <p class="answer-text mt-1">
                  {{ conclusion }}
                </p>
              </div>
            </section>

            <section class="doctorine mt-4">
              <div class="tabs">
                <div class="tab d-flex gap-2 align-items-center">
                  <h6 class="question m-0">
                    Doctrines({{ doctrines.length }})
                  </h6>
                </div>
              </div>
              <div id="doctrine-content" v-if="doctrines.length > 0">
                <div
                  v-for="(doctrine, index) in visibleDoctrines"
                  :key="index"
                  class="tabs"
                >
                  <div class="tab d-flex gap-2 align-items-center">
                    <span class="doctorine-text mt-4">{{
                      doctrine.doctrine_name
                    }}</span>
                  </div>
                  <div class="pt-2">
                    <p class="answer-text mt-1">
                      Status:
                      <span
                        class="ms-2"
                        style="color: var(--Status-Green, #52c41a) !important"
                        >{{ doctrine.doctrine_status }}</span
                      >
                    </p>
                    <p class="answer-text" style="margin: 0px !important">
                      Recent Finalized Cases:
                    </p>
                    <button class="p-0 mt-1">
                      <span class="text-link"
                        ><a
                          :href="doctrine.doctrine_case_link"
                          target="_blank"
                          >{{ doctrine.doctrine_case }}</a
                        ></span
                      >
                    </button>
                    <p class="answer-text mt-1">{{ doctrine.summary }}</p>
                  </div>
                </div>
              </div>
              <div v-else>No doctrines found in this case.</div>
              <div
                v-if="doctrines.length > 2"
                class="d-flex justify-content-center align-items-center"
              >
                <button
                  @click="toggleShowAll"
                  class="d-flex align-items-center"
                  style="color: var(--Primary-Blue, #0e4485)"
                >
                  <span class="material-symbols-rounded icon m-2">
                    {{ showAll ? "remove" : "add" }}
                  </span>
                  {{ showAll ? "Show Less" : "Show More" }}
                </button>
              </div>
            </section>

            <section class="citations mt-4" id="citations">
              <div class="tabs">
                <div class="tab d-flex gap-2 align-items-center">
                  <h6 class="question m-0">Citations({{ totalCitations }})</h6>
                </div>
              </div>
              <div class="tabs m-3">
                <div
                  v-for="(citation, index) in case_laws"
                  :key="citation.id"
                  class="m-1"
                >
                  <span class="index-link">{{ index }}. </span>
                  <span class="text-link">
                    <a :href="citation.Link" target="_blank">{{
                      citation.Name
                    }}</a>
                  </span>
                </div>
                <div
                  v-for="(citation, index) in legislations"
                  :key="citation.id"
                  class="m-1"
                >
                  <span class="index-link ms-1">{{ index }}. </span>
                  <span class="text-link" style="padding: 5px !important">
                    <a :href="citation.href" target="_blank">{{
                      citation.name
                    }}</a>
                  </span>
                </div>
              </div>
            </section>
          </main>
        </div>
      </div>
    </div>
    <div v-if="!loading" class="wrapper-chatbox">
      <ChatBox @chat-submit="submitQuery" />
    </div>
    <sidebar
      v-if="sidebarCollapsed"
      @toggle-sidebar="toggleSidebar"
      :uploadFiles="files"
    ></sidebar>
  </div>
</template>
<script>
import { ExportDataPDF } from "@/store/utils";
import ChatBox from "@/components/input/ChatBox.vue";
import countryImages from "@/assets/country/index";
import loader from "@/Pages/Mode/loader.vue";
import sidebar from "../sidebar.vue";
import usecase from "@/store/usecase.js";
import nexa from "@/store/nexa.js";
import FollowUpQuestionBox from "@/components/input/FollowUpQuestionBox.vue";
// import VueMarked from "vue-marked";
import marked from "marked";
export default {
  components: {
    loader,
    sidebar,
    ChatBox,
    FollowUpQuestionBox,
    // VueMarked,
  },
  data() {
    return {
      loading: true,
      isDropdown: false,
      isCopied: false,
      duration: -1,
      interval: null,
      sidebarCollapsed: false,
      lawbotVisible: false,
      isCountry: false,
      showSummary: false,
      countryImages,
      files: [],
      questions: [],
      question: "",
      answer: "",
      legislations: {},
      case_laws: {},
      discussion: "",
      jurisdiction: "",
      activeTab: "case_laws",
      followUpQuestion: "",
      collapsedQuestions: [],
      isFetching: false,
      showAll: false,
      isDropdownOpen: [],
      doctrines: [],
      // doctrines: [
      //   {
      //     doctrine_name: "Minnesota Franchise Act 1 A",
      //     doctrine_status: "Not Overturned",
      //     doctrine_case:
      //       "Cambria Company, LLC v. M&M Creative Laminants, Inc. dba M&M Creative Laminates, Inc., ... (Minn. 2024)",
      //     doctrine_case_link:
      //       "https://www.courtlistener.com/opinion/10129055/cambria-company-llc-v-mm-creative-laminants-inc-dba-mm-creative/",
      //     summary:
      //       "This case finalized the doctrine’s application when a natural disaster made contractual performance impossible. The court ruled that the contract was void due to frustration.",
      //   },
      //   {
      //     doctrine_name: "Minnesota Franchise Act 2 B",
      //     doctrine_status: "Not Overturned",
      //     doctrine_case:
      //       "Cambria Company, LLC v. M&M Creative Laminants, Inc. dba M&M Creative Laminates, Inc., ... (Minn. 2024)",
      //     doctrine_case_link:
      //       "https://www.courtlistener.com/opinion/10129055/cambria-company-llc-v-mm-creative-laminants-inc-dba-mm-creative/",
      //     summary:
      //       "This case finalized the doctrine’s application when a natural disaster made contractual performance impossible. The court ruled that the contract was void due to frustration.",
      //   },
      //   {
      //     doctrine_name: "Minnesota Franchise Act 3 C",
      //     doctrine_status: "Not Overturned",
      //     doctrine_case:
      //       "Cambria Company, LLC v. M&M Creative Laminants, Inc. dba M&M Creative Laminates, Inc., ... (Minn. 2024)",
      //     doctrine_case_link:
      //       "https://www.courtlistener.com/opinion/10129055/cambria-company-llc-v-mm-creative-laminants-inc-dba-mm-creative/",
      //     summary:
      //       "This case finalized the doctrine’s application when a natural disaster made contractual performance impossible. The court ruled that the contract was void due to frustration.",
      //   },
      // ],

      optionsContent: {
        modules: {
          toolbar: [
            [{ size: ["small", false, "large", "huge"] }], // custom dropdown
            [{ align: [] }, { list: "ordered" }, { list: "bullet" }],
            [{ color: [] }, { background: [] }],
            ["bold", "italic", "underline", "strike", "code-block"],
            [{ list: "bullet" }, { list: "ordered" }],
          ],
        },
      },
    };
  },
  computed: {
    filteredQuestions() {
      return this.questions.slice(1);
    },
    parsedCaseLaws() {
      return Object.values(this.case_laws);
    },
    parsedLegislations() {
      return Object.values(this.legislations);
    },
    totalCitations() {
      return (
        Object.keys(this.case_laws).length +
        Object.keys(this.legislations).length
      );
    },
    visibleDoctrines() {
      return this.showAll ? this.doctrines : this.doctrines.slice(0, 2);
    },
  },
  methods: {
    async refetchAnswers() {
      if (this.isFetching) return;
      this.isFetching = true;

      const checkAllAnswers = () =>
        this.followUpQuestion.every((q) => q.answer !== null);

      while (!checkAllAnswers()) {
        const updatedQuestions = await Promise.all(
          this.followUpQuestion.map(async (element) => {
            if (element.answer == null) {
              try {
                const response = await usecase.GetResult(
                  this.$route.params.id ?? ""
                );

                const updatedQuestion = response.data.data.questions.find(
                  (q) => q.id === element.id
                );

                if (updatedQuestion && updatedQuestion.answer !== null) {
                  return {
                    ...element,
                    answer: updatedQuestion.answer,
                  };
                }
                return element;
              } catch (error) {
                return element;
              }
            }
            return element;
          })
        );

        this.followUpQuestion = updatedQuestions;

        await new Promise((resolve) => setTimeout(resolve, 2000));
      }
      this.isFetching = false;
    },
    toggleDropdown(index, state) {
      this.$set(this.isDropdownOpen, index, state);
    },
    toggleShowAll() {
      this.showAll = !this.showAll;
    },
    fetchData() {
      this.refetchAnswers();
    },

    submitQuery(question) {
      const formData = new FormData();
      formData.append("language", "en");
      formData.append("location_id", 1);
      formData.append("question", question);

      this.blockUser = true;
      nexa
        .DetailAsk(this.$route.params.id, formData)
        .then((response) => {
          const responseData = response.data?.data;
          const responseQuestions = responseData?.questions;
          this.refetchAnswers();

          if (responseQuestions && Array.isArray(responseQuestions)) {
            responseQuestions.forEach((q) => {
              const existing = this.questions.find(
                (existing) => existing.id === q.id
              );
              if (existing) {
                existing.answer = q.answer;
              } else {
                this.questions.push(q);
              }
            });

            this.refetchAnswers();

            if (response.data.completedPercentage === 100) {
              this.blockUser = false;
            }
          } else {
            console.error(
              "Error: responseQuestions is undefined or not an array",
              response
            );
          }
        })
        .catch((error) => {
          console.error("Error submitting query:", error);
          this.blockUser = false;
        });
    },

    viewSummary() {
      this.$router.push({ name: "BuildMemoSummary" });
    },
    toggleSidebar() {
      this.sidebarCollapsed = !this.sidebarCollapsed;
    },
    goBack() {
      this.$router.push({ name: "ExpertMode" });
    },

    toggleCountry() {
      this.isCountry = !this.isCountry;
    },
    showAttachModal() {
      this.$bvModal.show("attachfile");
    },
    hideAttachModal() {
      this.$bvModal.hide("attachfile");
    },
    copy() {
      let introduction;
      let discussion;
      let conclusion;
      let doctrinesTitle;
      let doctrinesContent;
      let citation;

      introduction = `## Introduction\n\n${this.introduction}\n\n`;
      discussion = `## Discussion\n\n${this.discussion}\n\n`;
      conclusion = `## Conclusion\n\n${this.conclusion}\n\n`;
      doctrinesTitle = `## Doctrines\n\n`;

      doctrinesContent = document.querySelector("#doctrine-content").innerText;
      citation = document.querySelector("#citations").innerText;

      const contentToCopy =
        introduction +
        discussion +
        conclusion +
        doctrinesTitle +
        doctrinesContent +
        "\n\n---\n\n" +
        citation;

      navigator.clipboard
        .writeText(contentToCopy)
        .then(() => {
          this.$toast.success("Copied as plain text successfully!");
          this.isCopied = true;
        })
        .catch(() => {
          this.$toast.error("Error copying to clipboard");
        })
        .finally(() => {
          setTimeout(() => {
            this.isCopied = false;
          }, 2000);
        });
    },

    exportToPDF() {
      try {
        this.showAll = true;

        this.$nextTick(() => {
          let introduction;
          let discussion;
          let conclusion;
          let doctrinesTitle;
          let doctrinesContent;
          let citation;
          const followUpQuestion = `<h1 style="font-size: 24px; font-weight: bold;">Draf a Legal Memo: ${this.$route.params.id}</h1><br><hr>`;
          const tempDiv = document.createElement("div");

          tempDiv.style.width = "65%";
          tempDiv.style.maxWidth = "800px";
          tempDiv.style.margin = "20px auto";
          tempDiv.style.padding = "20px";
          tempDiv.style.border = "1px solid #ccc";
          tempDiv.style.borderRadius = "10px";
          tempDiv.style.backgroundColor = "#f9f9f9";
          tempDiv.style.boxShadow = "0 2px 10px rgba(0, 0, 0, 0.1)";
          tempDiv.style.fontFamily = "Arial, sans-serif";
          introduction = ` <h6 class="question mt-2">Introduction</h6><br><br><p style="margin-top: 4px;">${this.introduction}</p><br><br>`;
          discussion = ` <h6 class="question mt-2">Discussion</h6><br><br><p style="margin-top: 4px;">${this.discussion}</p><br><br>`;
          conclusion = ` <h6 class="question mt-2">Conclusion</h6><br><br><p style="margin-top: 4px;">${this.conclusion}</p><br><br>`;
          doctrinesTitle = ` <h6 class="question mt-2">Doctrines</h6><br><br>`;

          doctrinesContent =
            document.querySelector("#doctrine-content").outerHTML;

          citation = document.querySelector("#citations").outerHTML;
          tempDiv.innerHTML =
            followUpQuestion +
            introduction +
            discussion +
            conclusion +
            doctrinesTitle +
            doctrinesContent +
            "<br><hr>" +
            citation;

          document.body.appendChild(tempDiv);
          ExportDataPDF(
            tempDiv.innerHTML,
            false,
            `Draft a Legal Memo: ${this.$route.params.id}`
          );
          this.$toast.success("Successfully downloaded PDF");
          document.body.removeChild(tempDiv);
        });
      } catch (err) {
        this.$toast.error("Error exporting to PDF");
      } finally {
        this.showAll = false;
      }
    },
    handleSubmit() {
      if (this.followUpQuestion.length > 0) {
        const payload = {
          language: "en",
          location_id: 1,
          question: this.followUpQuestion,
        };

        nexa
          .DetailAsk(this.$route.params.id, payload)
          .then((response) => {
            const newResponse = response.data.data;
            console.log(newResponse);
            this.followUpQuestion = "";
            this.$toast.success("Successful");
          })
          .catch((error) => {
            console.error("Error sending question:", error);
          });
      }
    },
    formatDateTime(date) {
      return new Date(date).toISOString();
    },
    getTimeDifference(date) {
      const now = new Date();
      const createdAt = new Date(date);
      const diffInMinutes = Math.floor((now - createdAt) / (1000 * 60));

      if (diffInMinutes < 60) {
        return `${diffInMinutes} m`;
      } else {
        const diffInHours = Math.floor(diffInMinutes / 60);
        return `${diffInHours} h`;
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  created() {
    usecase.GetResult(this.$route.params.id ?? "").then((response) => {
      const DATA = response.data.data;
      if (DATA.completedPerCentage >= 100) {
        this.loading = false;
      }

      if (DATA.completedPerCentage < 100) {
        if (DATA.completedPerCentage > 0)
          this.duration = DATA.completedPerCentage;

        this.interval = setInterval(() => {
          usecase.GetResult(this.$route.params.id).then((res) => {
            if (res.data.data.completedPerCentage >= 100) {
              this.duration = res.data.data.completedPerCentage;
              clearInterval(this.interval);
              location.reload();
            } else if (res.data.data.completedPerCentage > 0) {
              this.duration = res.data.data.completedPerCentage;
            }
          });
        }, 10000);
      } else {
        this.duration = 100;

        this.question = DATA.questions[0].question;
        this.answer = DATA.questions[0].answer;
        this.jurisdiction = Object.keys(JSON.parse(DATA.jurisdiction))[0];
        this.legislations = JSON.parse(
          DATA.values.find((el) => el.key === "nexa_legislations").value
        );
        this.case_laws = JSON.parse(
          DATA.values.find((el) => el.key === "nexa_caselaws").value
        );
        this.doctrines = JSON.parse(
          DATA.values.find((el) => el.key === "nexa_doctrines").value
        );

        this.files = DATA.questionDocuments;

        this.discussion = marked.parse(
          JSON.parse(
            DATA.values.find((el) => el.key === "nexa_discussion").value
          )
        );

        this.introduction = JSON.parse(
          DATA.values.find((el) => el.key === "nexa_introduction").value
        );
        this.conclusion = JSON.parse(
          DATA.values.find((el) => el.key === "nexa_conclusion").value
        );
        this.nexa = JSON.parse(
          DATA.values.find((el) => el.key === "nexa_discussion").value
        );
        this.file_value = JSON.parse(
          DATA.values.find((el) => el.key === "nexa_files").value
        );
        this.questions = DATA.questions || [];

        // const arrayDocumentID = DATA.questionDocuments.map((item) => item.id);
        // usecase
        //   .Summary(this.$route.params.id, {
        //     summarize_doc_ids: arrayDocumentID,
        //   })
        //   .then((response) => {
        //     console.log(response);
        //   });

        const hasNullValue = this.questions.some((q) => q.answer === null);

        if (hasNullValue) {
          this.refetchAnswers();
        }

        this.collapsedQuestions = this.questions.map((_, index) => index);

        // nexa.AllResult().then(response => {
        //   const DATA = response.data.data.data;
        //   this.questions = DATA.map(item => {
        //     return {
        //       id: item.id,
        //       title: item.title,
        //       fileCount: item.file_uploaded_count,
        //       createdAt: item.created_at,
        //     };
        //   });
        // }).catch(error => {
        //   console.error('Error fetching AllResult:', error);
        // });
      }
    });
  },
};
</script>

<style scoped>
.rotate-180 {
  transform: rotate(180deg);
  transition: transform 0.3s ease;
}

.page-content {
  padding: 0px;
  overflow-x: hidden;
}

.overlay-container {
  width: 25%;
  position: absolute;
  top: 8.7vh;
  right: 0;
  z-index: 1000;
  height: 100vh;
}

.breadcrumb_header {
  display: flex;
  justify-content: space-between;
  height: 68px;
  background: #fff;
  /* padding: 16px 20px; */
  align-items: center;
}

.breadcrumb {
  display: flex;
  align-items: center;
  margin: auto 0;
}
.breadcrumb-text {
  color: var(--Neutral-Dark-Grey, #86888d);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.open_sidebar {
  display: flex;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;

  border-radius: 4px;
  background: var(--Primary-Blue, #0e4485);
}

.open_sidebar span {
  color: #fff;
  display: flex;
  /* Ensure icon is aligned vertically */
  align-items: center;
  /* Align icon vertically within span */
}

.open_sidebar p {
  color: #fff !important;
  margin: 0;
  /* Remove any default margin to align the text properly */
  line-height: 1;
  /* Ensure text has the same line height as the icon */
  display: flex;
  align-items: center;
  /* Align text vertically */
}

.divider {
  display: inline-block;
  margin: 0px 16px;
  width: 1px;
  height: 32px;
  background-color: var(--mid-grey);
}

.back {
  color: var(--primary) !important;
  font-weight: 500;
}
</style>
<style scoped>
.content {
  border-radius: 8px;
  background: var(--Neutral-White, #fff);
  box-shadow: 0 2px 4px 0 rgba(170, 171, 175, 0.6);
  display: flex;
  flex-direction: column;
  padding: 22px 24px;
}

@media (max-width: 991px) {
  .content {
    padding: 0 20px;
  }
}

.intro,
.question-section,
.answer-section,
.discussion,
.doctorine,
.citations,
.legal-references {
  max-width: 100%;
}

.quillEditor .ql-toolbar.ql-snow {
  border-radius: 8px 0px !important;
  border-bottom: 1px solid #d1d2d5 !important;
  background: #f2f3f3 !important;
}

.legal-references {
  margin-bottom: 5rem;
}

.title {
  color: var(--Primary-Blue, #0e4485) !important;
  font: 500 16px/28px Poppins, sans-serif;
}

.separator {
  background: #f2f3f3;
  height: 1px;
  width: 100%;
}

.question-section .tabs,
.answer-section .answer-header,
.legal-references {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  height: 25px;
}

.result-tabs {
  display: flex;
  width: 100%;
  background: var(--Neutral-Light-Grey, #f2f3f3);
  align-items: center;
}

.question .button,
.actions .button,
.tab {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  border-radius: 4px;
}

h6.title.answer-title.case-title {
  color: var(--Primary-Blue, #0e4485) !important;
  margin: 0px;
}

.img {
  aspect-ratio: 1/1;
  width: 18px;
  object-fit: contain;
}

.question-description,
.answer-text {
  margin-top: 4px;
  color: var(--Neutral-Dark-Grey, #86888d) !important;
  font: 400 14px/24px Poppins, sans-serif;
}

.doctorine-text {
  color: var(--Neutral-Dark-Grey, #86888d);

  /* Button */
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.text-link {
  color: var(--Status-Blue, #1890ff);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  text-decoration-line: underline;
}

.index-link {
  color: var(--Status-Blue, #1890ff);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.answer-title,
.reference-title {
  color: var(--Primary-Blue, #0e4485) !important;
  font-size: 16px;
  line-height: 28px;
}

.actions .button {
  border: 1px solid var(--Neutral-Mid-Grey, #d1d2d5);
  background: var(--Neutral-White, #fff);
  color: var(--Primary-Dark-Blue, #000046) !important;
}

.actions .button-primary {
  background: var(--Primary-Blue, #0e4485);
  color: var(--Neutral-White, #fff) !important;
}

.actions .button,
.actions .button-primary {
  padding: 2px 8px;
}

.details .detail {
  color: var(--Neutral-Dark-Grey, #86888d) !important;
  font-size: 14px;
}

.detail-title {
  color: var(--Primary-Blue, #0e4485) !important;
  font-size: 16px;
}

.detail-text {
  margin-top: 4px;
  color: var(--Neutral-Dark-Grey, #86888d) !important;
}

.link {
  color: var(--Status-Blue, #1890ff) !important;
}

.results {
  background: var(--Neutral-Interface-Grey, #fafbfc);
  border: 1px solid var(--Neutral-Light-Grey, #f2f3f3);
  border-radius: 8px;
}

.result-tabs .tab {
  background: var(--Neutral-Light-Grey, #f2f3f3);
  color: var(--Neutral-Dark-Grey, #86888d);
  padding: 8px 16px;
  font-weight: 500;
  border-radius: 0px !important;
}

.result-tabs .tab.active {
  border-bottom: 2px solid var(--Primary-Blue, #0e4485);
  color: var(--Primary-Blue, #0e4485) !important;
}

.case-result {
  border: 1px solid var(--Neutral-Light-Grey, #f2f3f3);
  border-radius: 8px;
  background: var(--Neutral-White, #fff);
  padding: 20px 16px;
  margin-top: 20px;
}

.case-title {
  font-size: 16px;
  color: var(--Neutral-Black, #383a3e) !important;
  font-weight: 500;
}

.case-details .meta {
  display: flex;
  align-items: center;
}

.case-details .meta img {
  margin-right: 4px;
}

.case-overview,
.case-analysis p {
  font-size: 14px;
  color: var(--Neutral-Dark-Grey, #86888d) !important;
  line-height: 24px;
}

.relevancy {
  display: flex;
  align-items: center;
  color: var(--Status-Green, #52c41a) !important;
}

.relevancy img {
  margin-right: 4px;
}

.actions {
  display: flex;
  gap: 2px;
}
</style>

<style scoped>
.follow-up-question {
  justify-content: center;
  border-radius: 16px;
  border: 1px solid var(--Primary-Blue, #0e4485);
  background: var(--Neutral-White, #fff);
  box-shadow: 0 2px 10px 0 rgba(14, 68, 133, 0.6);
  z-index: 10;
  display: flex;
  width: 74%;
  gap: 8px;
  flex-wrap: wrap;
  padding: 12px 20px;
  align-items: center;
  position: fixed;
  bottom: 20px;
}

.input-field {
  align-self: stretch;
  min-width: 240px;
  flex: 1;
  padding: 10px 16px 10px 0;
  font: 400 16px Poppins, sans-serif;
  border: none;
  outline: none;
  background: transparent;
}

.follow-up-actions {
  display: flex;
  padding-right: 20px;
  align-items: center;
  justify-content: start;
  height: 100%;
  width: 56px;
}

.submit-button {
  border-radius: 4px;
  align-self: stretch;
  display: flex;
  width: 36px;
  align-items: center;
  justify-content: center;
  margin: auto 0;
  padding: 6px;
  background: none;
  border: none;
  cursor: pointer;
}

.submit-icon {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 24px;
  align-self: stretch;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

@media (max-width: 991px) {
  .follow-up-question,
  .input-field {
    max-width: 100%;
  }
}
</style>

<style>
#dropdown-right .dropdown-toggle:focus,
#dropdown-right .dropdown-toggle:active,
#dropdown-right .dropdown-toggle.show,
#dropdown-right .dropdown-menu {
  outline: none;
  box-shadow: none;
}

#dropdown-right .dropdown-item:active,
#dropdown-right .dropdown-item:focus {
  color: black !important;
  background-color: transparent !important;
}

.case-summary {
  border-collapse: collapse; /* Ensure borders are collapsed */
}

.case-summary th {
  padding: 9px 20px 9px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  border: 1px solid var(--Neutral-Dark-Grey, #86888d);
  border-radius: 8px;
}

.case-summary td {
  padding: 9px 4px 9px 16px;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--Neutral-Dark-Grey, #86888d);
  border-bottom: 1px solid var(--Neutral-Dark-Grey, #86888d) !important;
}

.claims-container {
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 4px 10px rgba(227, 229, 235, 0.6);
  display: flex;
  flex-direction: column;
  font: 400 14px/1.5 Poppins, sans-serif;
  padding: 22px 24px;
  margin-bottom: 2rem;
  transition: height 0.3s ease;
  cursor: pointer;
}

.claims-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
  /* flex-wrap: wrap; */
}

.title-group {
  display: flex;
  min-width: 240px;
  align-items: center;
  gap: 8px;

  line-height: 28px;
}
.overlay-container {
  width: 25%;
  position: fixed;
  top: 8.7vh;
  right: 0;
  z-index: 1000;

  height: 100vh;
}
.primary-title {
  color: #0e4485;
  font-family: Poppins;
  font-size: 1rem !important;
  font-style: normal;
  font-weight: 500;
  line-height: 1.75rem;
}

.secondary-title {
  color: #383a3e;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.75rem;
}

.action-buttons {
  display: flex;
  align-items: center;
  gap: 8px;
}

.action-btn {
  display: flex;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  padding: 2px 8px;
  font-size: 14px;
  cursor: pointer;
  border: none;
  background: none;
}

.dropdown-text {
  color: var(--Neutral-Black, #383a3e);
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.copy-btn {
  color: #0e4485;
}

.export-btn {
  background: #0e4485;
  color: #fff;
}

.icon {
  width: 18px;
  object-fit: contain;
}

.action-icon {
  width: 18px;
  height: 18px;
}

.claims-content {
  margin: 32px 0;

  color: var(--Neutral-Dark-Grey, #86888d) !important;
  font-family: Poppins, sans-serif !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 1.5rem !important;
}

.claims-table {
  border: 1px solid #86888d;
  border-radius: 8px;
  overflow: hidden;
}

.table-header {
  display: flex;
  background: #f2f3f3;
  border-bottom: 1px solid #86888d;
}

.column-title {
  flex: 1;
  min-width: 240px;
  padding: 9px 16px;
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  color: #383a3e;
}

.column-title:first-child {
  border-right: 1px solid #86888d;
}

.table-row {
  display: flex;
  border-bottom: 1px solid #86888d;
}

.row-label {
  flex: 1;
  min-width: 240px;
  padding: 9px 16px;
  border-right: 1px solid #86888d;
}

.row-content {
  flex: 1;
  min-width: 240px;
  padding: 9px 16px;
}

.wrapper-chatbox {
  position: fixed;
  right: 2rem;

  width: 80%;
  z-index: 30;
  bottom: 2rem;
}

.skeleton-loader {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.skeleton-item {
  border-radius: 12px;
  background-color: rgba(243, 243, 243, 1);
  min-height: 28px;
}

.skeleton-item-large {
  width: 535px;
  max-width: 100%;
}

.skeleton-item-full {
  width: 100%;
  margin-top: 8px;
}
.action-button {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 4px 8px;
  border-radius: 4px;
  cursor: pointer;
}
.copy-button {
  color: #0e4485;
}
.export-button {
  background: #0e4485;
  color: #fff;
}
</style>
