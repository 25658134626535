import { render, staticRenderFns } from "./legalmemo_result.vue?vue&type=template&id=2605bfc0&scoped=true"
import script from "./legalmemo_result.vue?vue&type=script&lang=js"
export * from "./legalmemo_result.vue?vue&type=script&lang=js"
import style0 from "./legalmemo_result.vue?vue&type=style&index=0&id=2605bfc0&prod&scoped=true&lang=css"
import style1 from "./legalmemo_result.vue?vue&type=style&index=1&id=2605bfc0&prod&scoped=true&lang=css"
import style2 from "./legalmemo_result.vue?vue&type=style&index=2&id=2605bfc0&prod&scoped=true&lang=css"
import style3 from "./legalmemo_result.vue?vue&type=style&index=3&id=2605bfc0&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2605bfc0",
  null
  
)

export default component.exports